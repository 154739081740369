import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jamesabaja/MedGrocer/maersk-pbm/src/components/Layout/Layout.js";
import { graphql } from "gatsby";
import Completed from '../../components/Completed/Completed';
export const query = graphql`
  query {
    completed: file(relativePath: { eq: "complete__medicines.png" })
    {
      childImageSharp {
        fluid(
          maxWidth: 512
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
   }`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Completed icon={props.data.completed.childImageSharp.fluid} mdxType="Completed">
      <p className="py-1">
  A MedGrocer representative will contact you through email to confirm your order. If you
  don't receive an email within the next 1-2 business days, please contact us at{" "}
  <a href={`mailto:${process.env.GATSBY_AWS_SES_EMAIL_TO}`} className="has-text-weight-bold">
    {process.env.GATSBY_AWS_SES_EMAIL_TO}
  </a>
  .
      </p>
    </Completed>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      