import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Container from "layout/Container"

import styles from "./utils/completed.module.scss"

const Completed = ({ children, icon }) => (
  <Container isCentered>
    <center className={classNames(styles["completed"])}>
      <h1 className="title is-size-3 my-2">Thank you!</h1>
      <Container isCentered mobile={8} tablet={6} desktop={4}>
        <Img fluid={icon} alt="Success!" />
      </Container>
      {children}
      <Link to="/">
        <button className="button is-primary is-medium">Finish</button>
      </Link>
    </center>
  </Container>
)

export default Completed
